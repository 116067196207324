const availableRouteObj = {
    '3': [
        '/workspace/list',
        '/survey',
        '/survey/type',
        '/survey/template/create',
        '/survey/template/edit',
        '/survey/create',
        '/survey/status',
        '/survey/history',
    ],
    '4': [],
};

export const checkRouteRole = (pathname, role) => {
    if (parseInt(role) < 3) return true;
    const pathArray = pathname.replace('/', '').split('/');
    let routePathname = '';
    pathArray.forEach(item => {
        if (isNaN(parseInt(item))) routePathname += `/${item}`;
    });
    return availableRouteObj[role].indexOf(routePathname) !== -1;
};