import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import App from './App';
import reportWebVitals from './reportWebVitals';
import rootReducer from './service/redux/reducer';
import './translations/i18n';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icon.css';
import "react-multi-carousel/lib/styles.css";

// Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


Sentry.init({
    dsn: "https://f77f54c6b1d242c59688b9df13ab6021@sentry.ftsdn.com/17",
    integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
    tracesSampleRate: 0.25,
    replaysSessionSampleRate: 0.25,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_PROJECT_ENV ? process.env.REACT_APP_PROJECT_ENV : 'local',
});
  
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
