export const SET_THEME = 'SET_THEME';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_LOADING = 'SET_LOADING';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_UBOT_TOAST = 'SET_UBOT_TOAST';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_INSTITUTION_DATA = 'UPDATE_USER_INSTITUTION_DATA';
export const UPDATE_CURRENT_USER_INSTITUTION = 'UPDATE_CURRENT_USER_INSTITUTION';
export const UPDATE_INSTITUTION = 'UPDATE_INSTITUTION';

export const UPDATE_WORKSPACE_DATA = 'UPDATE_WORKSPACE_DATA';
export const UPDATE_CURRENT_WORKSPACE = 'UPDATE_CURRENT_WORKSPACE';

export const UPDATE_CONTACT_DATA = 'UPDATE_CONTACT_DATA';
export const UPDATE_CONTACT_FILTER = 'UPDATE_CONTACT_FILTER';

export const UPDATE_CAMPUS_DATA = 'UPDATE_CAMPUS_DATA';
export const UPDATE_CAREER_DATA = 'UPDATE_CAREER_DATA';
export const UPDATE_COURSE_DATA = 'UPDATE_COURSE_DATA';
export const UPDATE_TEMPLATE_DATA = 'UPDATE_TEMPLATE_DATA';
export const UPDATE_QUESTION_DATA = 'UPDATE_QUESTION_DATA';
export const UPDATE_ADMIN_DATA = 'UPDATE_ADMIN_DATA';

export const UPDATE_SURVEY_DATA = 'UPDATE_SURVEY_DATA';
export const UPDATE_SURVEY_TEMPLATE_DATA = 'UPDATE_SURVEY_TEMPLATE_DATA';
export const UPDATE_SURVEY_STATUS_FILTER = 'UPDATE_SURVEY_STATUS_FILTER';
export const UPDATE_SURVEY_HISTORY_FILTER = 'UPDATE_SURVEY_HISTORY_FILTER';

export const UPDATE_INTENT_DATA = 'UPDATE_INTENT_DATA';

export const UPDATE_STATISTICS_DATA = 'UPDATE_STATISTICS_DATA';
