import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    contactData: null,
    filterObj: {
        pageNum: 1,
        text: null,
        role: null,
        career: null,
        course: null,
        is_institution: true,
    },
};

const updateContactData = (state, action) => {
    return updateState(
        state,
        {
            contactData: action.contactData
        }
    )
};

const updateFilterObj = (state, action) => {
    return updateState(
        state,
        {
            filterObj: {
                ...state.filterObj,
                ...action.filterObj
            }
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_CONTACT_DATA: return updateContactData(state, action);
        case actionTypes.UPDATE_CONTACT_FILTER: return updateFilterObj(state, action);
        default: return state;
    }
};

export default reducer;