import React from 'react';
import { useSelector } from 'react-redux';

import Loader from './Loader';

import './style.less';

const ThemeLoader = () => {

    const loading = useSelector(state => state.theme.loading);

    return (
        loading ?
            <div className="theme-loader-wrapper">
                <Loader color="white" />
            </div> :
            null
    )
};

export default ThemeLoader