const defaultDefaultColor = {
    layoutBackground: '#F8F8F8',
    defaultColor: '#1D99F1',
    defaultLightColor: '#ebfaff',
    defaultColorHover: '#41a5ec',
    colorGrey: '#646464',
    colorBeauty: '#ECF1F4',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#fff',
    colorTitle: '#333',
};

const defaultDarkColor = {
    layoutBackground: '#292929',
    defaultColor: '#45A3E5',
    defaultLightColor: '#333',
    defaultColorHover: '#399ce1',
    colorGrey: '#b9b9b9',
    colorBeauty: '#A2A2A233',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#333333',
    colorTitle: '#f8f8f8',
};

const happyDefaultColor = {
    layoutBackground: '#F8F8F8',
    defaultColor: '#CF7C09',
    defaultLightColor: '#f9eedf',
    defaultColorHover: '#de9026',
    colorGrey: '#646464',
    colorBeauty: '#ECF1F4',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#fff',
    colorTitle: '#333',
};

const happyDarkColor = {
    layoutBackground: '#292929',
    defaultColor: '#D49B4E',
    defaultLightColor: '#333',
    defaultColorHover: '#ce9445',
    colorGrey: '#b9b9b9',
    colorBeauty: '#A2A2A233',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#333333',
    colorTitle: '#f8f8f8',
};

const warmDefaultColor = {
    layoutBackground: '#F8F8F8',
    defaultColor: '#C64467',
    defaultLightColor: '#fde3ea',
    defaultColorHover: '#ce5979',
    colorGrey: '#646464',
    colorBeauty: '#ECF1F4',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#fff',
    colorTitle: '#333',
};

const warmDarkColor = {
    layoutBackground: '#292929',
    defaultColor: '#C46780',
    defaultLightColor: '#333',
    defaultColorHover: '#c25e79',
    colorGrey: '#b9b9b9',
    colorBeauty: '#A2A2A233',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#333333',
    colorTitle: '#f8f8f8',
};

const oceanDefaultColor = {
    layoutBackground: '#F8F8F8',
    defaultColor: '#4365B6',
    defaultLightColor: '#dee5f5',
    defaultColorHover: '#5875bd',
    colorGrey: '#646464',
    colorBeauty: '#ECF1F4',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#fff',
    colorTitle: '#333',
};

const oceanDarkColor = {
    layoutBackground: '#292929',
    defaultColor: '#5F90B2',
    defaultLightColor: '#333',
    defaultColorHover: '#548aaf',
    colorGrey: '#b9b9b9',
    colorBeauty: '#A2A2A233',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#333333',
    colorTitle: '#f8f8f8',
};

const forestDefaultColor = {
    layoutBackground: '#F8F8F8',
    defaultColor: '#00C294',
    defaultLightColor: '#e7f9f5',
    defaultColorHover: '#17c79d',
    colorGrey: '#646464',
    colorBeauty: '#ECF1F4',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#fff',
    colorTitle: '#333',
};

const forestDarkColor = {
    layoutBackground: '#292929',
    defaultColor: '#5AA786',
    defaultLightColor: '#333',
    defaultColorHover: '#51a27f',
    colorGrey: '#b9b9b9',
    colorBeauty: '#A2A2A233',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#333333',
    colorTitle: '#f8f8f8',
};

const neutralDefaultColor = {
    layoutBackground: '#F8F8F8',
    defaultColor: '#333333',
    defaultLightColor: '#e1e1e1',
    defaultColorHover: '#484848',
    colorGrey: '#646464',
    colorBeauty: '#ECF1F4',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#fff',
    colorTitle: '#333',
};

const neutralDarkColor = {
    layoutBackground: '#292929',
    defaultColor: '#f8f8f8',
    defaultLightColor: '#333',
    defaultColorHover: '#b9b9b9',
    colorGrey: '#b9b9b9',
    colorBeauty: '#A2A2A233',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#333333',
    colorTitle: '#f8f8f8',
};

export const defaultBaseSchema = {
    layoutBackground: '#F8F8F8',
    layoutBackgroundDark: '#EAEAEA',
    colorGrey: '#646464',
    colorBeauty: '#ECF1F4',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#fff',
    colorTitle: '#333',
};

export const defaultDarkBaseSchema = {
    layoutBackground: '#292929',
    layoutBackgroundDark: '#414141',
    colorGrey: '#b9b9b9',
    colorBeauty: '#A2A2A233',
    colorLightGrey: '#A2A2A2',
    colorWhite: '#333333',
    colorTitle: '#f8f8f8',
}

export const colorSchema = {
    'default_default': defaultDefaultColor,
    'default_dark': defaultDarkColor,
    'happy_default': happyDefaultColor,
    'happy_dark': happyDarkColor,
    'warm_default': warmDefaultColor,
    'warm_dark': warmDarkColor,
    'ocean_default': oceanDefaultColor,
    'ocean_dark': oceanDarkColor,
    'forest_default': forestDefaultColor,
    'forest_dark': forestDarkColor,
    'neutral_default': neutralDefaultColor,
    'neutral_dark': neutralDarkColor,
};

export const defaultColorSchemaIdentifiers = ['default', 'happy', 'warm', 'ocean', 'forest', 'neutral'];