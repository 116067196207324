import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    surveyData: null,
    surveyTemplateData: null,
    surveyStatusFilter: {
        type: null,
        status: null,
        text: null,
    },
    surveyHistoryFilter: {
        type: null,
        text: null,
    },
};

const updateSurveyData = (state, action) => {
    return updateState(
        state,
        {
            surveyData: action.surveyData
        }
    )
};

const updateSurveyTemplateData = (state, action) => {
    return updateState(
        state,
        {
            surveyTemplateData: action.surveyTemplateData
        }
    )
};

const updateSurveyStatusFilter = (state, action) => {
    return updateState(
        state,
        {
            surveyStatusFilter: action.surveyStatusFilter,
        }
    )
};

const updateSurveyHistoryFilter = (state, action) => {
    return updateState(
        state,
        {
            surveyHistoryFilter: action.surveyHistoryFilter,
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SURVEY_DATA: return updateSurveyData(state, action);
        case actionTypes.UPDATE_SURVEY_TEMPLATE_DATA: return updateSurveyTemplateData(state, action);
        case actionTypes.UPDATE_SURVEY_STATUS_FILTER: return updateSurveyStatusFilter(state, action);
        case actionTypes.UPDATE_SURVEY_HISTORY_FILTER: return updateSurveyHistoryFilter(state, action);
        default: return state;
    }
};

export default reducer;