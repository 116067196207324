import {errorManagementHandler, setLoading} from "./theme";
import axios from "axios";
import {API_BASE_URL, LMS_URL} from "../../../util/constant";
import * as actionTypes from '../actionTypes';
import {checkRouteRole} from "../../../util/securityUtil";
// import {checkRouteRole} from "../../../util/securityUtil";

export const updateUserData = userData => {
    return {
        type: actionTypes.UPDATE_USER_DATA,
        userData
    }
};

export const updateUserInstitutionData = userInstitutionData => {
    return {
        type: actionTypes.UPDATE_USER_INSTITUTION_DATA,
        userInstitutionData
    }
};

export const updateCurrentUserInstitution = currentUserInstitution => {
    localStorage.setItem('institutionId', currentUserInstitution.institution);
    localStorage.setItem('role', currentUserInstitution.role);
    return {
        type: actionTypes.UPDATE_CURRENT_USER_INSTITUTION,
        currentUserInstitution: currentUserInstitution
    }
};

export const updateInstitution = institutionData => {
    return {
        type: actionTypes.UPDATE_INSTITUTION,
        institutionData: institutionData
    }
};

export const getUserData = (history, token, institutionId, role, pathname) => {
    return (dispatch) => {
        dispatch(setLoading(true));
        if (!!token) {
            localStorage.setItem('token', token);
            axios
                .get(`${API_BASE_URL}/auth/userData/getInstitutionUserData/${institutionId}`, {headers: {Authorization: `Bearer ${token}`}})
                .then(res => {
                    const userData = res.data.user_data;
                    const institutionData = res.data.institution_data;
                    let userInstitutionData = res.data.user_institution_data;
                    let userInstitution = userInstitutionData.filter(item => String(item.role) === String(role));
                    if (!userInstitution.length) window.open(`${LMS_URL}/lms`, '_parent');
                    if (!checkRouteRole(pathname, role)) history.replace('/workspace/list');
                    dispatch(updateUserInstitutionData(userInstitutionData))
                    dispatch(updateCurrentUserInstitution(userInstitution[0]));
                    dispatch(updateInstitution(institutionData));
                    dispatch(updateUserData(userData));
                    dispatch(setLoading(false));
                })
                .catch(e => dispatch(errorManagementHandler(e)));
        } else {
            dispatch(logout());
        }
    };
};

export const logout = () => {
    return dispatch => {
        dispatch(updateUserInstitutionData(null));
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        window.open(`${LMS_URL}/auth/login`, '_parent');
    }
};