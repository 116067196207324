export const TRANSLATIONS_ES = {
    // ERROR MESSAGE
    INTERNAL_SERVER_ERROR: 'Error de servidor interno',
    UNAUTHORIZED: 'No autorizado',
    TOKEN_EXPIRED: 'Token caducado',
    NOT_FOUND: 'Servicio no encontrado',
    METHOD_NOT_ALLOWED: 'Método de solicitud no permitido',
    USER_DUPLICATED: 'Usuario duplicado',
    BAD_REQUEST: 'Solicitud incorrecta',
    USER_NOT_FOUND: 'Usuario no encontrado',
    PHONE_REQUIRED: 'Requiere telefono',
    PHONE_DUPLICATED: 'Número de teléfono duplicado',
    EMAIL_REQUIRED: 'Correo electronico requerido',
    EMAIL_DUPLICATED: 'Correo electrónico duplicado',
    PASSWORD_REQUIRED: 'Se requiere contraseña',
    WRONG_PASSWORD: 'Contraseña incorrecta',
    INVALID_USER: 'Usuario invalido',
    NOT_EMAIL_OWNER: 'Tu no eres el propietario de este correo electrónico',
    NOT_PHONE_OWNER: 'Tu no eres el propietario de este teléfono',
    EMAIL_NOT_FOUND: 'Correo electrónico no encontrado',
    PHONE_NOT_FOUND: 'Teléfono no encontrado',
    SENDING_EMAIL_ERROR: 'Envío fallido de correo electrónico',
    SENDING_MESSAGE_ERROR: 'Envío fallido de mensaje',
    NO_PERMISSION: 'No tienes permiso para hacer esta acción',
    INSTITUTION_NOT_FOUND: 'Institución no encontrada',
    ACCOUNT_NOT_VERIFIED: 'Cuenta no verificada',
    INVALID_TOKEN: 'Código inválido',
    SOMETHING_WRONG: 'Algo salió mal, por favor, ponte en contacto con soporte técnico',
    INVALID_TEACHER: 'Profesor invalido',
    INVALID_STUDENT: 'Estudiante invalido',
    CONTACT_NOT_FOUND: 'Contacto no encontrado',
    MESSAGE_NOT_FOUND: 'Mensaje no encontrado',
    NOT_ALLOWED_ACTION: 'Esta acción no está permitida',

    ERROR_AVATAR_FILE_SIZE: 'Tamaño del archivo muy grande',

    ACCOUNT_UPDATED_SUCCESS: 'Cuenta actualizada exitosamente',
    CONTACT_UPDATED_SUCCESS: 'Contacto actualizado exitosamente',
    UPDATE_PASSWORD_SUCCESS: 'Contraseña actualizada exitosamente',
    SEND_VERIFY_EMAIL_SUCCESS: 'Verificación exitosa del correo electrónico',
    SEND_VERIFY_CODE_SUCCESS: 'Verificación exitosa del código',
    CREATE_OWNER_SUCCESS: 'Has agregado un nuevo propietario. El nuevo propietario tiene que verificar su cuenta',
    CREATE_ADMIN_SUCCESS: 'Has agregado un/a nuevo/a administrador/a',
    DELETE_ADMIN_SUCCESS: 'Has eliminado un/a administrador/a',
    DELETE_TEACHER_SUCCESS: 'Has eliminado un docente / facilitador',
    DELETE_TEACHER_MULTIPLE_SUCCESS: 'Has eliminado a {{number}} docentes / facilitadors',
    CREATE_COURSE_SUCCESS: 'Ha creado un curso, debe esperar la aprobación del administrador',
    DELETE_STUDENT_MULTIPLE_SUCCESS: 'Has eliminado a {{number}} alumnos',
    DELETE_STUDENT_SUCCESS: 'Has eliminado un estudiante',
    UPLOAD_TEACHER_MASSIVE_SUCCESS: 'La carga se está ejecutando en segundo plano, recibirá una notificación una vez completada',
    SEND_TEMPLATE_MESSAGE_SUCCESS: 'Enhorabuena, ha enviado un mensaje con éxito.',
    CREATE_SURVEY_TEMPLATE_SUCCESS: 'Enhorabuena, ha creado una nueva plantilla.',
    RESEND_SURVEY_SUCCESS: 'Has reenviado el mensaje con éxito',

    // FORM VALIDATION
    fieldRequired: 'Este campo es obligatorio',
    fieldInvalid: 'Valor no válido',
    weakPassword: 'Contraseña debil',
    fieldUnconfirmed: 'Por favor confirme',
    fieldMaxLength: 'Máxima {{length}} de caracteres',

    // FORM PLACEHOLDER
    user: 'Usuario',
    password: 'Contraseña',
    emailOrPhone: 'Dirección de correo electrónico o número de teléfono celular',
    enterCode: 'Ingrese su código',
    selectArea: 'Seleccione las areas',
    selectNativeLanguage: 'Seleccione idioma nativo',
    selectOtherLanguage: 'Seleccione otros idiomas',
    select: 'Seleccionar',
    placeholderExample: 'Ejemplo: {{text}}',
    filterStudyArea: 'Filtrar el área de estudio',
    filterCareerIndependentCourse: 'Filtrar carrera o curso independiente',

    // FORM LABEL
    name: 'Primer Nombre',
    lastname: "Primer Apellido",
    secondName: "Segundo Nombre",
    secondLastname: "Segundo Apellido",
    birthday: "Fecha de Nacimiento",
    nationality: 'Nacionalidad',
    idNumber: 'Número de Identificación',
    email: 'Correo Electrónico',
    phone: 'Teléfono Celular',
    repeatPassword: 'Repita su contraseña',
    enterEmailPhone: 'Ingrese si correo electrónico o teléfono celular',
    verificationCodeToEmail: 'Un código de verificación para restaurar su contraseña fue enviado a su correo electrónico',
    enterNewPassword: 'Ingrese su nueva contraseña',
    repeatNewPassword: 'Repita su nueva contraseña',
    country: 'País',
    city: 'Ciudad',
    state: 'Estado/Provincia',
    secondNationality: 'Segunda nacionalidad',
    secondIdentification: 'Segunda identificación',
    secondPassport: 'Segundo pasaporte',
    avatarOption1: "Seleccione una imagen de su computadora (máx. 1MB)",
    avatarOption2: "Cargue imagen desde link",
    avatarOption3: "Usar imagen por default",
    actual: "Actual",
    newPassword: "Nueva contraseña",
    confirmPassword: "Confirme nueva contraseña",
    interestArea: "Áreas de interés",
    nativeLanguage: "Idioma nativo",
    otherLanguage: "Otros Idiomas",
    surname: "Apellido",
    secondSurname: "Segundo Apellido",
    address: 'Dirección',
    editImage: "Editar imagen",
    ok: "OK",
    sector: "Sector",
    subSector: "Subsector",
    numberOfStudent: "Número de estudiantes",
    noInfo: "No info",
    numberOfCourse: "Número de cursos",
    institutionName: 'Nombre de la institución',
    institutionEmail: 'Correo electrónico de la institución',
    institutionPhone: 'Número telefónico de contacto',
    dateOfOrigin: 'Fecha de creación',
    description: 'Descripción',
    googleMapLink: 'Link a Google maps',
    website: 'Sitio web',
    title: 'Título',
    buttonText: 'Botón de texto',
    buttonLink: 'Botón de link',
    studyArea: 'Áreas de estudio',
    independenceCourseCareer: 'Curso de independencia / carrera',
    filteredCourse: 'Curso filtrado',
    courseSet: 'Conjunto de cursos',
    courseImageOption1: 'Seleccione una imagen de su computadora (máx. 1MB)',
    courseImageDescription1: 'El formato de la imagen debe ser JPG o PNG y tener un tamaño superior a 300px x 150px.',
    courseImageOption2: 'Cargue imagen desde link',
    courseImageOption3: 'Usar imagen por default',
    courseCoverLetterFileDescription: 'El formato de archivo debe ser docx o pdf',
    selectIfOpenCourse: 'Seleccionar si es un curso abierto',
    yes: 'Si',
    no: 'No',
    totalAcademicHour: 'Horas académicas totales',
    equivalentCredit: 'Créditos equivalentes / ECTS',
    synchronous: 'Sincrónico',
    asynchronous: 'Asincrónico',
    classDay: 'Día de clase{{index}}',
    time: 'Hora',
    startTime: 'Hora de inicio',
    finishTime: 'Tiempo de finalización',
    nameOfWorkspace: 'Nombre del espacio de trabajo',

    // WEEKDAY
    weekday0: 'Domingo',
    weekday1: 'Lunes',
    weekday2: 'Martes',
    weekday3: 'Miércoles',
    weekday4: 'Jueves',
    weekday5: 'Viernes',
    weekday6: 'Sábado',

    // SIDE MENU
    home: 'Inicio',
    contact: 'Contacto',
    contacts: 'Contactos',
    chat: 'Chat',
    chats: 'Chats',
    faq: 'FAQ',
    faqs: 'FAQs',
    survey: 'Encuesta',
    surveys: 'Encuestas',
    alert: 'Alerta',
    alerts: 'Alertas',
    data: 'Datos',
    sidebarFooter: '© Todos los derechos reservados',

    // STUDY AREA NAME
    studyAreaName_art_architecture_design: 'Arte, Arquitectura y Diseño',
    studyAreaName_biological_agriculture: 'Ciencias Biológicas y Agropecuarias',
    studyAreaName_economic_business: 'Ciencias Económicas, Negocios y Management',
    studyAreaName_educational: 'Ciencias de la Educación',
    studyAreaName_engineering: 'Ingenierías',
    studyAreaName_exact: 'Ciencias Exactas',
    studyAreaName_health: 'Ciencias de la Salud',
    studyAreaName_language: 'Idiomas',
    studyAreaName_social_humanity: 'Ciencias Sociales y Humanidades',
    studyAreaName_technology: 'Tecnología',

    // COURSE USER ROLE
    courseUserRole_teacher_instructor: 'Instructor/a',
    courseUserRole_teacher_assistance: 'Profesor/a Asistente',
    courseUserRole_teacher_associate: 'Profesor/a Asociado',
    courseUserRole_teacher_professor: 'Profesor/a Titular',

    // COURSE USER PERMISSION
    courseUserPermission_manage_content: 'Crear / Cargar contenidos, por supuesto',
    courseUserPermission_manage_activity: 'Calificar / Asistir / Comentar la actividad',
    courseUserPermission_chat: 'Chatear con el alumno',
    courseUserPermission_manage_course: 'Editar la información del curso',

    // HEADER
    notification: 'Notificaciones',
    makeAsReadAll: 'Marcar todas como leídas',
    makeAsRead: 'Marcar como leída',
    makeAsUnread: 'Hacer como no leído',
    deleteAll: 'Borrar todo',
    unread: 'No leídas',
    read: 'Leídas',
    goBackToUbotPlatform: 'Volver a Ubot',
    changeRole: 'Cambiar rol',
    academic: 'Académico',
    analytics: 'Analítica',
    communication: 'Comunicación',
    courses: 'Cursos',
    tutorials: 'Tutoriales',
    createYourSite: 'Crear tu sitio',
    profile: 'Perfil',
    configuration: 'Configuración',
    messageAndNotification: 'Mensajes y notificaciones',
    darkMode: 'Modo oscuro',
    signOut: 'Cerrar Sesión',
    teacher: 'Profesor',
    student: 'Estudiante',
    admin: 'Administrador',
    workspace: 'Espacios de trabajo',
    manageWorkspace: 'Administrar el espacio de trabajo',

    // NOTIFICATION DESCRIPTION
    systemMessage_newDate: '',
    systemMessage_assigned: 'Esta consulta ha sido asignada a {{assignedTo}}',
    you: 'ustedes',

    // ROLE NAME
    roleName1: 'Propietario',
    roleName2: 'Administrador',
    roleName3: 'Profesor',
    roleName4: 'Estudiante',

    // COMMON BUTTON
    delete: 'Borrar',
    view: 'Ver',
    edit: 'Editar',
    use: 'Usar',
    search: "Buscar",
    next: 'Próximo',
    back: 'Atrás',
    goBack: 'Regresa',
    add: 'Agregar',
    remove: 'Eliminar',
    finish: 'Terminar',
    cancel: 'Cancelar',
    save: 'Guardar',
    closePreview: 'Cerrar vista previa',
    addEditTeacher: 'Agregar / Editar profesor',
    addEditStudent: 'Agregar / editar alumno',

    // WORKSPACE
    createNew: "Crear nuevo",
    contactToAssignNewPhoneNumber: "Póngase en contacto con <a href='mailto:info@ubot.cl'>info@ubot.cl</a> para solicitar la asignación de un nuevo número de teléfono",
    botName: "Nombre del bot",
    botId: "ID de bot",
    botToken: "Token de bot",
    confirmDelete: "¿Quieres eliminar <b>{{name}}</b>?",
    confirmDeleteWorkspaceDescriptionFinal: "Esta acción es definitiva, si elimina este espacio de trabajo no tendrá acceso a él nuevamente",

    // HOME
    welcomeUser: '¡Bienvenido al espacio de trabajo de {{workspaceName}}!',
    access: 'Acceso',
    newContactNumber: 'Se han agregado <b>{{number}} nuevos contactos</b>',
    uncheckedMessage: 'Tienes <b>{{number}} mensajes</b> sin leer',
    recentlyUploadedFaq: 'Has subido recientemente <b>{{number}} preguntas frecuentes</b>',
    unsentSurvey: 'Tienes <b>{{number}} encuestas</b> sin enviar',
    latestSurvey: 'Últimas encuestas enviadas',
    recentFaq: 'Preguntas faqs',
    seeResult: 'Ver resultados',
    download: 'Descargar',
    viewMore: 'Ver más',

    // CONTACT
    showBy: 'Mostrar por',
    role: 'Rol',
    course: 'Curso',
    all: 'Todo',
    institutionContact: 'Contactos de la institucion',
    otherContact: 'Otros contactos',
    lastnamesAndNames: 'Apellido/s y Nombre/s',
    institutionContactWarningDescription: 'Los contactos de la institución no se pueden editar ni eliminar',
    sendMessage: 'Enviar mensaje',
    noData: 'Sin datos',
    newContact: 'Nuevo contacto',
    contactInformation: 'Información del contacto',
    personalInformation: 'Información personal',
    confirmDeleteDescriptionFinal: "Esta acción es definitiva, no se puede recuperar",
    page: 'Página',
    of: 'de',
    selectedContactNumber: 'Envía mensajes a {{number}} contactos',

    // SURVEY
    createSendSurveyTemplate: 'Crear / enviar encuestas y plantillas',
    shipmentStatusAlert: 'Estado de envío y alertas',
    historicalResult: 'Histórico y resultados',
    newSurvey: 'Nueva encuesta',
    editSurvey: 'Editar encuesta',
    surveyTemplate: 'Plantilla de encuesta',
    surveyTemplateDisplay: 'Visualización de plantilla de encuesta',
    surveyType1: 'Diagnóstico',
    surveyTypeIcon1: 'D',
    surveyTypeDescription1: 'Intereses o características de investigación de los asistentes a tus cursos: servicios tecnológicos, estado anterior, accesibilidad, etc.',
    surveyType2: 'Proceso de aprendizaje',
    surveyTypeIcon2: 'PA',
    surveyTypeDescription2: 'Pregunte por el estado de sus alumnos en cuanto a su desempeño, dificultad de su última actividad, buen uso de recursos, etc.',
    surveyType3: 'Satisfacción',
    surveyTypeIcon3: 'S',
    surveyTypeDescription3: 'Evalúa la experiencia de tus usuarios en relación a tutores, profesores, recursos, plataforma, etc.',
    blankSurvey: 'Encuesta en blanco',
    template: 'Plantilla',
    templates: 'Plantillas',
    noTemplate: 'No hay plantillas cargadas',
    surveyType: 'Tipo de encuesta',
    createNewTemplate: 'Crear nueva plantilla',
    newSurvey1: 'Nueva encuesta de diagnóstico',
    newSurvey2: 'Encuesta sobre el nuevo proceso de aprendizaje',
    newSurvey3: 'Nueva encuesta de satisfacción',
    createNewSurvey: 'Crear nueva encuesta',
    sendingSavingSurvey: 'Envío y / o guardado de encuesta',
    surveyTitle: 'Título de la encuesta',
    surveyDescription: 'Descripción de la encuesta',
    question: 'Pregunta',
    questionTitle: 'Título de la pregunta',
    surveyAnswerType1: 'Checkbox',
    surveyAnswerType2: 'Escala lineal',
    surveyAnswerType3: 'Múltiple opción',
    surveyAnswerType4: 'Respuesta corta',
    untitledQuestion: 'Pregunta sin título',
    enableComment: 'Habilitar comentario',
    selectCommentTitle: 'Seleccionar título de comentario',
    commentType1: 'Escribe un comentario al respecto',
    commentType2: '¿Por qué?',
    addNewField: 'Agregar nuevo campo',
    nextStep: 'Próximo paso',
    addOption: 'Agregar opción',
    to: 'y',
    labelOptional: 'Etiqueta (opcional)',
    label: 'Etiqueta',
    labels: 'Etiquetas',
    shipment: 'Envío',
    notSendNow: 'No enviar ahora',
    immediateShipping: 'Envio inmediato',
    scheduledShipping: 'Envío programado',
    mandatorySurvey: 'Encuesta de respuesta obligatoria',
    saveSurveyAsTemplate: 'Guardar encuesta como plantilla',
    selectDate: 'Seleccionar una fecha',
    responseDeadline: 'Plazo de respuesta',
    date: 'Fecha',
    selectTime: 'Seleccione una hora',
    selectGroup: 'Seleccionar un grupo',
    typeOfUser: 'Tipo de usuario',
    institutionUser: 'Usuario de la institución',
    otherUser: 'Otro usuario',
    addIndividualRecipient: 'Agregar destinatarios individuales',
    addIndividualRecipientDescription: 'Seleccione destinatarios individuales por nombre y número de teléfono',
    recipients: 'Destinatarios',
    templateTitle: 'Título de la plantilla',
    templateDescription: 'Descripción de la plantilla',
    addTemplateDescription: 'Agrega una breve descripción de la plantilla',
    client: 'Cliente',
    checkboxAnswerDescription: 'Puede seleccionar más de una opción, escriba los números separados por una coma (ej: 1, 3, 5)',
    editTemplate: 'Editar plantilla',
    useTemplate: 'Usar plantilla',
    shotAnswer: 'Respuesta corta',
    commentEnabled: 'Comentarios habilitados',
    createSurveyTemplate: 'Crear plantilla de encuesta',
    createNewSurveyTemplate: 'Crear nueva plantilla de encuesta',
    surveyTemplateEditing: 'Edición de plantilla de encuesta',
    surveyTemplateLock: 'Bloqueo de plantilla de encuesta',
    templateLock: 'Bloqueo de plantilla',
    canBeEdited: 'Se puede editar una vez guardado',
    unableToEdit: 'No se puede editar una vez guardado',
    editSurveyTemplate: 'Editar plantilla de encuesta',
    status: 'Estado',
    sentBy: 'Enviado por',
    receptor: 'Receptor',
    shippingDate: 'Fecha de envío',
    deadline: 'Fecha límite',
    draft: 'Borrador',
    toSend: 'Mandar',
    retryNumber: 'Número de reintento',
    inProcess: 'En proceso',
    finished: 'Finalizado',
    send: 'Enviar',
    resend: 'Reenviar',
    paused: 'Pausado',
    pending: 'Pendiente',
    error: 'Error',
    result: 'Resultado',
    results: 'Resultados',
    showComment: 'Mostrar comentario',
    hideComment: 'Ocultar comentario',
    downloadPDF: 'Descargar PDF',
    completePercentage: 'Completa {{percent}}%',
    answerNumber: 'Respuesta {{answerNumber}}/{{totalNumber}}',
    surveyStatus0: 'Borrador',
    surveyStatus1: 'Mandar',
    surveyStatus2: 'En proceso',
    surveyStatus3: 'Finalizado',
    sendingSurvey: 'Enviando encuesta',
    noCommentActivated: 'No hay comentarios activados',
    noAnswer: 'Sin respuesta',
    noComment: 'Sin comentarios',

    // CHAT
    moodAnalysis: 'Análisis del estado de ánimo',
    realtimeAnalysis: 'Análisis en tiempo real',
    moodAnalysisDescription: 'Este análisis proporciona información sobre el estado de satisfacción de los usuarios:',
    totalNumberOfChatAnalysis: 'Número total de chats analizados',
    verySatisfied: 'Muy satisfecho',
    satisfied: 'Satisfecho',
    neutral: 'Neutral',
    unsatisfied: 'Insatisfecho',
    veryUnsatisfied: 'Muy insatisfecho',
    hello: 'Hola!',
    youCanSelectConversationFromInbox: 'Puede seleccionar una conversación en la bandeja de entrada.',
    stopQuery: 'Detener consulta',
    finishConsultation: 'Terminar consulta',
    later: 'Más tarde',
    "1hour": '1 hora',
    tomorrow: 'Mañana',
    thisWeek: 'Esta semana',
    fri8am: 'Fry 8:00 AM',
    nextWeek: 'La próxima semana',
    mon8am: 'Mon 8:00 AM',
    selectDateTime: 'Seleccionar fecha y hora',
    message: 'Mensaje',
    receiverName: 'Nombre del destinatario',
    senderName: 'Nombre del remitente',
    newMessage: 'Nuevo mensaje',
    filter: 'Filtrar',
    frequent: 'Frecuente',
    selectAll: 'Seleccionar todo',
    conversationStateCWS: 'Smarty está respondiendo a este chat',
    conversationStateAS: 'El estudiante está respondiendo la encuesta',
    conversationStateCWA: 'Smarty se activará automáticamente en {{min}} minutos',
    stopChatbot: 'DETENER CHATBOT',
    activateChatbot: 'ACTIVAR CHATBOT',
    whatsappLimitTitle: 'Has alcanzado tu límite de 24 horas en WhatsApp',
    whatsappLimitDescription: 'Hay una ventana de 24 horas para charlar con un cliente sin pagar tarifas de servicio adicionales. Puede mantenerse en contacto enviando un mensaje de plantilla de WhatsApp aprobado.',
    chooseTemplate: 'Elegir la plantilla',
    selectTypeOfUser: 'Seleccionar tipo de usuario',
    campus: "Sede",
    selectCampus: "Seleccionar sede",
    career: "Carrera",
    selectCareer: "Seleccionar carrera",
    selectCourse: 'Seleccionar curso',
    selectCourseSet: 'Seleccionar conjunto de cursos',
    selectRole: 'Seleccionar rol',
    applyFilter: 'Aplicar filtro',
    closeFilter: 'Cerrar filtro',
    currentQuery: 'Consulta actual',
    userCurrentMood: 'Estado de ánimo actual del usuario',
    assignedTo: 'Asignado a',
    contactStatus_CWS: 'Chatear con Smarty',
    contactStatus_CWT: 'Chatear con el profesor',
    contactStatus_CWA: 'Chatear con el administrador',
    contactStatus_PS: 'Encuesta',
    contactStatus_AS: 'Encuesta',
    deletedUser: 'Usuario eliminado',
    broadcastReceiverNumber: 'A {{number}} estudiante(s)',
    changeMessage: 'Cambiar mensaje',
    cancelMessage: 'Cancelar mensaje',
    cancelSchedule: 'Cancelar horario',
    sendWelcomeMessage: 'Enviar mensaje de bienvenida',
    scheduledDeliveryStatus: 'Estado de entrega programada',
    sendDateAndTime: 'Enviar fecha y hora',
    eventDateAndTime: 'Fecha y hora del evento',
    actions: 'Comportamiento',
    welcomeMessagePopupTitle: '¿Deseas enviar el mensaje de bienvenida a todos tus estudiantes?',
    welcomeMessagePopupDescription1: 'Sólo se le enviará el mensaje a los estudiantes que no lo hayan recibido anteriormente.',
    welcomeMessagePopupDescription2: 'Vista previa del mensaje',
    studentName: 'Nombre del estudiante',
    loadMore: 'Carga más',

    // FAQ
    faqDescription: 'Establezca las respuestas que sus usuarios buscan con frecuencia. Luego, puede agregar varias preguntas redactadas de manera diferente que conduzcan a la misma respuesta. Esto hará que tu bot esté súper entrenado para ser mucho más preciso.',
    example: 'Ejemplo',
    questionExample: '¿Cuándo abre su negocio?',
    answer: 'Respuesta',
    answers: 'Respuestas',
    answerExample: 'Lunes a viernes de 9:30 a 18:30 h.',
    intent: 'Intención',
    knowOpenHour: 'Conozca el horario de apertura',
    questionAndPhrase: 'Pregunta y frase',
    synchronization: 'Sincronización',
    questionNumber: '{{number}} preguntas',
    selectTemplate: 'Seleccionar plantilla',
    newMessageTo: 'Nuevo mensaje para',
    templatePreviewDescription1: 'Este es un modo de vista previa.',
    templatePreviewDescription2: 'El mensaje debe programarse para enviarse en el futuro o para enviarlo ahora',
    customFiled: 'Campo personalizado',
    scheduleSend: 'Programar envío',
    sendNow: 'Enviar ahora',
    confirmScheduledDelivery: 'Confirmar entrega programada',
    messageScheduleDescription: 'Este mensaje se enviará a las ',
    sendMessageToWhatsappAccount: 'Enviar mensaje a la cuenta de WhatsApp',
    viewContact: 'Ver contacto',
    addQuestion: 'Agregar pregunta',
    addAnswer: 'Agregar respuesta',
    newFaq: 'Nuevas FAQ',
    intentSynchronized: 'Sincronizado! Tu bot está entrenado.',
    intentUnSynchronized: '¡Error de sincronización! Haga clic en el icono para volver a intentarlo.',
    newQuestion: 'Nueva pregunta',
    newAnswer: 'Nueva respuesta',
    beCareful: '¡Ten cuidado!',
    confirmDeleteIntentDescription: 'Está a punto de eliminar todas las preguntas y respuestas vinculadas a su intención.',
    wannaContinue: '¿Quieres continuar?',

    // DATA
    statistics: 'Estadísticas',
    monthlyMessageDistribution: 'Distribución de mensajes mensuales',
    selectMonth: 'Seleccione mes',
    smarty: 'Smarty',
    assistantTotal: 'Asistente total',
    SENT_MESSAGE: 'MENSAJE ENVIADO',
    SHIPPING_ERROR: 'ERROR DE ENVÍO',
    SOLVED_ERROR: 'ERROR SOLUCIONADO',
    messageSession: 'Sesión de mensajes',
    CHANNELS: 'CANALES',
    summary: 'Resumen',
    messageHistory: 'Historial de mensajes',
    assistant: 'Asistente',
    historyGraphDescription: '* Se mostrará el historial de los 12 meses anteriores a la fecha seleccionada',
    notifications: 'Notificaciones',
    month1: 'Ene',
    month2: 'Feb',
    month3: 'Mar',
    month4: 'Abr',
    month5: 'May',
    month6: 'Jun',
    month7: 'Jul',
    month8: 'Ago',
    month9: 'Sep',
    month10: 'Oct',
    month11: 'Nov',
    month12: 'Dic',
    MONTH: 'MES',
    interactionWithSmartyMenu: 'Interacciones: con menú Smarty',
    numberOfQueries: 'Número de consultas',
    menu: 'Menú',
    smartyResponse: 'Respuestas de Smarty',
    download_xlsx: 'Descargar excel',
    init_date: "fecha inicial",
    end_date: "fecha final",
    download_xlsx_success: 'Descarga exitosa',
    download_xlsx_fail: 'Falla en descargar',
}