import {combineReducers} from 'redux'

import theme from './theme';
import data from './data';
import auth from './auth';
import workspace from './workspace';
import contact from './contact';
import survey from './survey';
import faq from './faq';

export default combineReducers({
    theme,
    data,
    auth,
    workspace,
    contact,
    survey,
    faq,
})