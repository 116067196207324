import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    intentData: null,
};

const updateIntentData = (state, action) => {
    return updateState(
        state,
        {
            intentData: action.intentData
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_INTENT_DATA: return updateIntentData(state, action);
        default: return state;
    }
};

export default reducer;