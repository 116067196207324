import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Spinner from 'react-spinkit';

const propTypes = {
    color: PropTypes.string,
    height: PropTypes.number,
};

const Loader = ({color, height}) => {

    return (
        <Wrapper height={height}>
            <Spinner name="double-bounce" color={!!color ? color : "grey"}/>
        </Wrapper>
    )
};

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  height: ${props => props.height ? props.height + 'px' : 'auto'};
  display: flex;
  justify-content: center;
  align-items: center;
`;

Loader.propTypes = propTypes;

export default Loader