import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    campusData: null,
    careerData: null,
    courseData: null,
    templateData: null,
    questionData: null,
    adminData: null,
};

const updateCampusData = (state, action) => {
    return updateState(
        state,
        {
            campusData: action.campusData,
        }
    )
};

const updateCareerData = (state, action) => {
    return updateState(
        state,
        {
            careerData: action.careerData,
        }
    )
};

const updateCourseData = (state, action) => {
    return updateState(
        state,
        {
            courseData: action.courseData
        }
    )
};

const updateTemplateData = (state, action) => {
    return updateState(
        state,
        {
            templateData: action.templateData
        }
    )
};

const updateQuestionData = (state, action) => {
    return updateState(
        state,
        {
            questionData: action.questionData
        }
    )
};

const updateAdminData = (state, action) => {
    return updateState(
        state,
        {
            adminData: action.adminData
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_CAMPUS_DATA: return updateCampusData(state, action);
        case actionTypes.UPDATE_CAREER_DATA: return updateCareerData(state, action);
        case actionTypes.UPDATE_COURSE_DATA: return updateCourseData(state, action);
        case actionTypes.UPDATE_TEMPLATE_DATA: return updateTemplateData(state, action);
        case actionTypes.UPDATE_QUESTION_DATA: return updateQuestionData(state, action);
        case actionTypes.UPDATE_ADMIN_DATA: return updateAdminData(state, action);
        default: return state;
    }
};

export default reducer;