import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";

import {CheckCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";

import {setUbotToast} from "../../service/redux/action/theme";

import './style.less';

const propTypes = {
    visible: PropTypes.bool,
    type: PropTypes.string,
    content: PropTypes.string,
};

const UbotToast = ({visible, type, content}) => {

    const dispatch = useDispatch();

    return (
        <div className={visible ? 'ubot-toast show' : 'ubot-toast'} onClick={() => dispatch(setUbotToast(null, null))}>
            {
                type === 'success' ? <CheckCircleOutlined className='color-green toast-icon'/> : null
            }
            {
                type === 'error' ? <InfoCircleOutlined className='color-yellow toast-icon'/> : null
            }
            <div className='content'>{content}</div>
        </div>
    )
};

UbotToast.propTypes = propTypes;

export default UbotToast;