export const TRANSLATIONS_EN = {
    // ERROR MESSAGE
    INTERNAL_SERVER_ERROR: 'Internal server error',
    UNAUTHORIZED: 'Unauthorized',
    TOKEN_EXPIRED: 'Token expired',
    NOT_FOUND: 'Service not found',
    METHOD_NOT_ALLOWED: 'Request method not allowed',
    USER_DUPLICATED: 'Duplicated user',
    BAD_REQUEST: 'Bad request',
    USER_NOT_FOUND: 'User not found',
    PHONE_REQUIRED: 'Phone required',
    PHONE_DUPLICATED: 'Duplicated phone number',
    EMAIL_REQUIRED: 'Email required',
    EMAIL_DUPLICATED: 'Duplicated email',
    PASSWORD_REQUIRED: 'Password required',
    WRONG_PASSWORD: 'Wrong password',
    INVALID_USER: 'Invalid user',
    NOT_EMAIL_OWNER: 'You are not owner of this email',
    NOT_PHONE_OWNER: 'You are not owner of this phone',
    EMAIL_NOT_FOUND: 'Email not found',
    PHONE_NOT_FOUND: 'Phone not found',
    SENDING_EMAIL_ERROR: 'Sending email failed',
    SENDING_MESSAGE_ERROR: 'Sending message failed',
    NO_PERMISSION: 'You have no permission to do this action',
    INSTITUTION_NOT_FOUND: 'Institution not found',
    ACCOUNT_NOT_VERIFIED: 'Account not verified',
    INVALID_TOKEN: 'Invalid token',
    SOMETHING_WRONG: 'Something went wrong, please contact with support',
    INVALID_TEACHER: 'Invalid teacher',
    INVALID_STUDENT: 'Invalid student',
    CONTACT_NOT_FOUND: 'Contact not found',
    MESSAGE_NOT_FOUND: 'Message not found',
    NOT_ALLOWED_ACTION: 'This action is not allowed',

    ERROR_AVATAR_FILE_SIZE: 'File size is too large',

    ACCOUNT_UPDATED_SUCCESS: 'Account updated successfully',
    CONTACT_UPDATED_SUCCESS: 'Contact updated successfully',
    UPDATE_PASSWORD_SUCCESS: 'Password updated successfully',
    SEND_VERIFY_EMAIL_SUCCESS: 'Verify email sent successfully',
    SEND_VERIFY_CODE_SUCCESS: 'Verify code sent successfully',
    CREATE_OWNER_SUCCESS: 'You have added a new owner. The new owner has to verify the account',
    CREATE_ADMIN_SUCCESS: 'You have added a new administrator',
    DELETE_ADMIN_SUCCESS: 'You have deleted an administrator',
    DELETE_TEACHER_SUCCESS: 'You have deleted a teacher / trainer',
    DELETE_TEACHER_MULTIPLE_SUCCESS: 'You have deleted {{number}} teachers / trainers',
    CREATE_COURSE_SUCCESS: 'You have created a course, you must wait for the administrator approval',
    DELETE_STUDENT_MULTIPLE_SUCCESS: 'You have deleted {{number}} students',
    DELETE_STUDENT_SUCCESS: 'You have deleted a student',
    UPLOAD_TEACHER_MASSIVE_SUCCESS: 'Upload is running in background, you will receive a notification after completed',
    SEND_TEMPLATE_MESSAGE_SUCCESS: 'Congratulation, you have sent a message successfully',
    CREATE_SURVEY_TEMPLATE_SUCCESS: 'Congratulation, you have created a new template',
    RESEND_SURVEY_SUCCESS: 'You have resent the message successfully',

    // FORM VALIDATION
    fieldRequired: 'This field is required',
    fieldInvalid: 'Invalid value',
    weakPassword: 'Weak password',
    fieldUnconfirmed: 'Confirm field correctly',
    fieldMaxLength: 'Maximum {{length}} characters',

    // FORM PLACEHOLDER
    user: 'User',
    password: 'Password',
    emailOrPhone: 'E-mail address or Cellphone number',
    enterCode: 'Enter your code',
    selectArea: 'Select areas',
    selectNativeLanguage: 'Select native language',
    selectOtherLanguage: 'Select other language',
    select: 'Select',
    placeholderExample: 'Example: {{text}}',
    filterStudyArea: 'Filter study area',
    filterCareerIndependentCourse: 'Filter career or independent course',

    // FORM LABEL
    name: 'Name',
    lastname: "Last name",
    secondName: "Second name",
    surname: "Surname",
    secondLastname: "Second last name",
    secondSurname: "Second surname",
    birthday: "Birth date",
    nationality: 'Nationality',
    idNumber: 'ID number',
    email: 'Email',
    phone: 'Mobile',
    repeatPassword: 'Repeat password',
    enterEmailPhone: 'Enter your email, phone',
    verificationCodeToEmail: 'A verification code to retrieve the password was sent to your email',
    enterNewPassword: 'Enter your new password',
    repeatNewPassword: 'Repeat your new password',
    country: 'Country',
    city: 'City',
    address: 'Address',
    state: 'State',
    secondNationality: 'Second nationality',
    secondIdentification: 'Second identification',
    secondPassport: 'Second passport',
    avatarOption1: "Select image from computer (maximum 1MB)",
    avatarOption2: "Load image from link",
    avatarOption3: "Use default image",
    editImage: "Edit image",
    ok: "OK",
    actual: "Actual",
    newPassword: "New password",
    confirmPassword: "Confirm new password",
    interestArea: "Interest areas",
    nativeLanguage: "Native language",
    otherLanguage: "Other language",
    sector: "Sector",
    subSector: "Sub - Sector",
    numberOfStudent: "Number of students",
    noInfo: "No info",
    numberOfCourse: "Number of courses",
    institutionName: 'Institution name',
    institutionEmail: 'Institution email',
    institutionPhone: 'Contact phone number',
    dateOfOrigin: 'Date of origin',
    description: 'Description',
    googleMapLink: 'Link to google map',
    website: 'Website',
    title: 'Title',
    buttonText: 'Button text',
    buttonLink: 'Button link',
    studyArea: 'Study area',
    independenceCourseCareer: 'Independence course / career',
    filteredCourse: 'Filtered course',
    courseSet: 'Course set',
    courseImageOption1: 'Upload file from your computer ({{size}}MB max)',
    courseImageDescription1: 'The image format must be JPG or PNG and be larger than 300px x 150px.',
    courseImageOption2: 'Upload file from link',
    courseImageOption3: 'Use default image',
    courseCoverLetterFileDescription: 'The file format must be docx or pdf',
    selectIfOpenCourse: 'Select if it is a open course',
    yes: 'Yes',
    no: 'No',
    totalAcademicHour: 'Total academic hours',
    equivalentCredit: 'Equivalent credits / ECTS',
    synchronous: 'Synchronous',
    asynchronous: 'Asynchronous',
    classDay: 'Class day{{index}}',
    time: 'Time',
    startTime: 'Start time',
    finishTime: 'Finish time',
    nameOfWorkspace: 'Name of workspace',

    // WEEKDAY
    weekday0: 'Sunday',
    weekday1: 'Monday',
    weekday2: 'Tuesday',
    weekday3: 'Wednesday',
    weekday4: 'Thursday',
    weekday5: 'Friday',
    weekday6: 'Saturday',

    // SIDE MENU
    home: 'Home',
    contact: 'Contact',
    contacts: 'Contacts',
    chat: 'Chat',
    chats: 'Chats',
    faq: 'FAQ',
    faqs: 'FAQs',
    survey: 'Survey',
    surveys: 'Surveys',
    alert: 'Alert',
    alerts: 'Alerts',
    data: 'Data',
    sidebarFooter: '© All rights reserved',

    // STUDY AREA NAME
    studyAreaName_art_architecture_design: 'Art, Architecture and Design',
    studyAreaName_biological_agriculture: 'Biological and Agricultural Sciences',
    studyAreaName_economic_business: 'Economic Sciences and Business Management',
    studyAreaName_educational: 'Educational Sciences',
    studyAreaName_engineering: 'Engineering',
    studyAreaName_exact: 'Exact Sciences',
    studyAreaName_health: 'Health Sciences',
    studyAreaName_language: 'Languages',
    studyAreaName_social_humanity: 'Social Sciences and Humanities',
    studyAreaName_technology: 'Technology',

    // COURSE USER ROLE
    courseUserRole_teacher_instructor: 'Instructor',
    courseUserRole_teacher_assistance: 'Assistant Professor/Teacher',
    courseUserRole_teacher_associate: 'Associate Professor/Teacher',
    courseUserRole_teacher_professor: 'Professor/Teacher',

    // COURSE USER PERMISSION
    courseUserPermission_manage_content: 'Create / Upload contents of course',
    courseUserPermission_manage_activity: 'Qualify / Take attendance / Comment on activity',
    courseUserPermission_chat: 'Chat with student',
    courseUserPermission_manage_course: 'Edit course info',

    // HEADER
    notification: 'Notification',
    makeAsReadAll: 'Make as read all',
    makeAsRead: 'Make as read',
    makeAsUnread: 'Make as unread',
    deleteAll: 'Delete all',
    unread: 'Unread',
    read: 'Read',
    goBackToUbotPlatform: 'Go back to Ubot platform',
    changeRole: 'Change role',
    academic: 'Academic',
    analytics: 'Analytics',
    communication: 'Communication',
    teacher: 'Teacher',
    student: 'Student',
    admin: 'Admin',
    courses: 'Courses',
    tutorials: 'Tutorials',
    createYourSite: 'Create your site',
    profile: 'Profile',
    configuration: 'Configuration',
    messageAndNotification: 'Messages and notifications',
    darkMode: 'Dark mode',
    signOut: 'Logout',
    workspace: 'Workspace',
    manageWorkspace: 'Manage workspace',

    // NOTIFICATION
    systemMessage_newDate: '',
    systemMessage_assigned: 'This consultation has been assigned to {{assignedTo}}',
    you: 'you',

    // ROLE NAME
    roleName1: 'Owner',
    roleName2: 'Admin',
    roleName3: 'Teacher',
    roleName4: 'Student',

    // COMMON BUTTON
    delete: 'Delete',
    view: 'View',
    edit: 'Edit',
    use: 'Use',
    search: "Search",
    next: 'Next',
    back: 'Back',
    goBack: 'Go back',
    add: 'Add',
    remove: 'Remove',
    finish: 'Finish',
    cancel: 'Cancel',
    save: 'Save',
    closePreview: 'Close preview',
    addEditTeacher: 'Add / Edit teacher',
    addEditStudent: 'Add / Edit student',

    // WORKSPACE
    createNew: "Create new",
    contactToAssignNewPhoneNumber: "Contact <a href='mailto:info@ubot.cl'>info@ubot.cl</a> to request the assignment of a new phone number",
    botName: "Bot name",
    botId: "Bot ID",
    botToken: "Bot token",
    confirmDelete: "Do you want to delete <b>{{name}}</b>?",
    confirmDeleteWorkspaceDescriptionFinal: "This action is final, if you delete this workspace, you will not have access to it again",

    // HOME
    welcomeUser: 'Welcome to the {{workspaceName}} workspace!',
    access: 'Access',
    newContactNumber: '<b>{{number}} new contacts</b> has been added',
    uncheckedMessage: 'You have <b>{{number}} unchecked</b> messages',
    recentlyUploadedFaq: 'You have recently uploaded <b>{{number}} FAQs</b>',
    unsentSurvey: 'You have <b>{{number}} unsent</b> surveys',
    latestSurvey: 'Latest surveys sent',
    recentFaq: 'Recent faqs',
    seeResult: 'See results',
    download: 'Download',
    viewMore: 'View more',

    // CONTACT
    showBy: 'Show by',
    role: 'Role',
    course: 'Course',
    all: 'All',
    institutionContact: 'Institution contacts',
    otherContact: 'Other contacts',
    lastnamesAndNames: 'Last name/s and name/s',
    institutionContactWarningDescription: 'Institution contacts cannot be edited or deleted',
    sendMessage: 'Send message',
    noData: 'No data',
    newContact: 'New contact',
    contactInformation: 'Contact information',
    personalInformation: 'Personal information',
    confirmDeleteDescriptionFinal: "This action is final, you cannot recover this action",
    page: 'Page',
    of: 'of',
    selectedContactNumber: 'Send messages to {{number}} contacts',

    // SURVEY
    createSendSurveyTemplate: 'Create / send surveys and templates',
    shipmentStatusAlert: 'Shipment status and alerts',
    historicalResult: 'Historical and results',
    newSurvey: 'New survey',
    editSurvey: 'Edit survey',
    surveyTemplate: 'Survey template',
    surveyTemplateDisplay: 'Survey template display',
    surveyTypeIcon1: 'D',
    surveyType1: 'Diagnostic',
    surveyTypeDescription1: 'Research interests or characteristics of those attending your courses: technological services, previous status, accessibility, etc.',
    surveyTypeIcon2: 'PA',
    surveyType2: 'Learning process',
    surveyTypeDescription2: 'Ask about the status of your students regarding their performance, difficulty of their last activity, good use of resources, etc.',
    surveyTypeIcon3: 'S',
    surveyType3: 'Satisfaction',
    surveyTypeDescription3: 'Evaluate the experience of your users in relation to tutors, teachers, resources, platform, etc.',
    blankSurvey: 'Blank survey',
    template: 'Template',
    templates: 'Templates',
    noTemplate: 'No template loaded',
    surveyType: 'Survey type',
    createNewTemplate: 'Create new template',
    newSurvey1: 'New diagnostic survey',
    newSurvey2: 'New learning process survey',
    newSurvey3: 'New satisfaction survey',
    createNewSurvey: 'Create new survey',
    sendingSavingSurvey: 'Sending and / or saving of survey',
    surveyTitle: 'Survey title',
    surveyDescription: 'Survey description',
    question: 'Question',
    questionTitle: 'Question title',
    surveyAnswerType1: 'Checkbox',
    surveyAnswerType2: 'Linear scale',
    surveyAnswerType3: 'Multiple choice',
    surveyAnswerType4: 'Short answer',
    untitledQuestion: 'Untitled question',
    enableComment: 'Enable comment',
    selectCommentTitle: 'Select comment title',
    commentType1: 'Write a comment about it',
    commentType2: 'Why?',
    addNewField: 'Add new field',
    nextStep: 'Next step',
    addOption: 'Add option',
    to: 'to',
    labelOptional: 'Label (optional)',
    label: 'Label',
    labels: 'Labels',
    shipment: 'Shipment',
    notSendNow: 'Do not send now',
    immediateShipping: 'Immediate shipping',
    scheduledShipping: 'Scheduled shipping',
    mandatorySurvey: 'Mandatory response survey',
    saveSurveyAsTemplate: 'Save survey as template',
    selectDate: 'Select a date',
    responseDeadline: 'Response deadline',
    date: 'Date',
    selectTime: 'Select a time',
    selectGroup: 'Select a group',
    typeOfUser: 'Type of user',
    institutionUser: 'Institution user',
    otherUser: 'Other user',
    addIndividualRecipient: 'Add individual recipients',
    addIndividualRecipientDescription: 'Select individual recipients by name and phone number',
    recipients: 'Recipients',
    templateTitle: 'Template title',
    templateDescription: 'Template description',
    addTemplateDescription: 'Add a short description of the template',
    client: 'Client',
    checkboxAnswerDescription: 'You can select more than one option, write the numbers separated by a comma (ex: 1, 3, 5)',
    editTemplate: 'Edit template',
    useTemplate: 'Use template',
    shotAnswer: 'Short answer',
    commentEnabled: 'Comments enabled',
    createSurveyTemplate: 'Create survey template',
    createNewSurveyTemplate: 'Create new survey template',
    surveyTemplateEditing: 'Survey template editing',
    surveyTemplateLock: 'Survey template lock',
    templateLock: 'Template lock',
    canBeEdited: 'Can be edited once saved',
    unableToEdit: 'Unable to edit once saved',
    editSurveyTemplate: 'Edit survey template',
    status: 'Status',
    sentBy: 'Sent by',
    receptor: 'Receptor',
    shippingDate: 'Shipping date',
    deadline: 'Deadline',
    draft: 'Draft',
    toSend: 'To send',
    retryNumber: 'Retry number',
    inProcess: 'In process',
    finished: 'Finished',
    send: 'Send',
    resend: 'Resend',
    paused: 'Paused',
    pending: 'Pending',
    error: 'Error',
    result: 'Result',
    results: 'Results',
    showComment: 'Show comment',
    hideComment: 'Hide comment',
    downloadPDF: 'Download PDF',
    completePercentage: 'Complete {{percent}}%',
    answerNumber: 'Answer {{answerNumber}}/{{totalNumber}}',
    surveyStatus0: 'Draft',
    surveyStatus1: 'To send',
    surveyStatus2: 'In process',
    surveyStatus3: 'Finished',
    sendingSurvey: 'Sending survey',
    noCommentActivated: 'No comments activated',
    noAnswer: 'No answer',
    noComment: 'No comment',

    // CHAT
    moodAnalysis: 'Mood analysis',
    realtimeAnalysis: 'Real time analysis',
    moodAnalysisDescription: 'This analysis provides information on the state of user satisfaction:',
    totalNumberOfChatAnalysis: 'Total number of chats analyzed',
    verySatisfied: 'Very satisfied',
    satisfied: 'Satisfied',
    neutral: 'Neutral',
    unsatisfied: 'Unsatisfied',
    veryUnsatisfied: 'Very unsatisfied',
    hello: 'Hello!',
    youCanSelectConversationFromInbox: 'You can select a conversation from then inbox.',
    stopQuery: 'Stop query',
    finishConsultation: 'Finish consultation',
    later: 'Later',
    "1hour": '1 hour',
    tomorrow: 'Tomorrow',
    thisWeek: 'This week',
    fri8am: 'Fri 8:00 AM',
    nextWeek: 'Next week',
    mon8am: 'Mon 8:00 AM',
    selectDateTime: 'Select date and time',
    receiverName: 'Receiver name',
    senderName: 'Sender name',
    message: 'Message',
    newMessage: 'New message',
    filter: 'Filter',
    frequent: 'Frequent',
    selectAll: 'Select all',
    conversationStateCWS: 'Smarty is answering this chat',
    conversationStateAS: 'Student is answering the survey',
    conversationStateCWA: 'Smarty will be activated automatically in {{min}} mins',
    stopChatbot: 'STOP CHATBOT',
    activateChatbot: 'ACTIVATE CHATBOT',
    whatsappLimitTitle: 'You have reached your limit of 24 hours on WhatsApp',
    whatsappLimitDescription: 'There is a 24-hour window to chat with a customer without paying additional service fees. You can keep in touch by sending an approved WhatsApp template message.',
    chooseTemplate: 'Choose template',
    selectTypeOfUser: 'Select type of user',
    campus: "Campus",
    selectCampus: "Select campus",
    career: "Career",
    selectCareer: "Select career",
    selectCourse: 'Select course',
    selectCourseSet: 'Select course set',
    selectRole: 'Select role',
    applyFilter: 'Apply filter',
    closeFilter: 'Close filter',
    currentQuery: 'Current query',
    userCurrentMood: 'User current mood',
    assignedTo: 'Assigned to',
    contactStatus_CWS: 'Chat with Smarty',
    contactStatus_CWT: 'Chat with Teacher',
    contactStatus_CWA: 'Chat with Admin',
    contactStatus_PS: 'Survey',
    contactStatus_AS: 'Survey',
    deletedUser: 'Deleted user',
    broadcastReceiverNumber: 'To {{number}} student(s)',
    changeMessage: 'Change message',
    cancelMessage: 'Cancel message',
    cancelSchedule: 'Cancel schedule',
    sendWelcomeMessage: 'Send welcome message',
    scheduledDeliveryStatus: 'Scheduled delivery status',
    sendDateAndTime: 'Send date and time',
    eventDateAndTime: 'Event date and time',
    actions: 'Actions',
    welcomeMessagePopupTitle: 'Do you want to send the welcome message to all your students?',
    welcomeMessagePopupDescription1: 'The message will be sent to only students who had not received it previously.',
    welcomeMessagePopupDescription2: 'Message preview',
    studentName: 'Student name',
    loadMore: 'Load more',

    // FAQ
    faqDescription: 'Set the answers your users are looking for frequently. Then you can add several differently worded questions that lead to the same answer. This will make your bot super trained to be much more accurate.',
    example: 'Example',
    questionExample: 'When is your business open?',
    answer: 'Answer',
    answers: 'Answers',
    answerExample: 'Monday to Friday, from 9:30 a.m. to 6:30 p.m.',
    intent: 'Intent',
    knowOpenHour: 'Know opening hour',
    questionAndPhrase: 'Question and phrase',
    synchronization: 'Synchronization',
    questionNumber: '{{number}} questions',
    selectTemplate: 'Select template',
    newMessageTo: 'New message to',
    templatePreviewDescription1: 'This is a preview mode.',
    templatePreviewDescription2: 'The message has to be scheduled to been sent in the future or for send it now',
    customFiled: 'Custom field',
    scheduleSend: 'Schedule send',
    sendNow: 'Send now',
    confirmScheduledDelivery: 'Confirm scheduled delivery',
    messageScheduleDescription: 'This message will be sent at ',
    sendMessageToWhatsappAccount: 'Send message to WhatsApp account',
    viewContact: 'View contact',
    addQuestion: 'Add question',
    addAnswer: 'Add answer',
    newFaq: 'New FAQ',
    intentSynchronized: 'Synchronized! Your bot is trained.',
    intentUnSynchronized: 'Synchronization error! Click on the icon to try again.',
    newQuestion: 'New question',
    newAnswer: 'New answer',
    beCareful: 'Be careful!',
    confirmDeleteIntentDescription: 'You are about to delete all the questions and answers linked to your intention.',
    wannaContinue: 'Do you want to continue?',

    // DATA
    statistics: 'Statistics',
    monthlyMessageDistribution: 'Monthly message distribution',
    selectMonth: 'Select month',
    smarty: 'Smarty',
    assistantTotal: 'Assistant total',
    SENT_MESSAGE: 'SENT MESSAGE',
    SHIPPING_ERROR: 'SHIPPING ERROR',
    SOLVED_ERROR: 'SOLVED ERROR',
    messageSession: 'Message session',
    CHANNELS: 'CHANNELS',
    summary: 'Summary',
    messageHistory: 'Message history',
    assistant: 'Assistant',
    historyGraphDescription: '* The history of the 12 months prior to the selected date will be displayed',
    notifications: 'Notifications',
    month1: 'Jan',
    month2: 'Feb',
    month3: 'Mar',
    month4: 'Apr',
    month5: 'May',
    month6: 'Jun',
    month7: 'Jul',
    month8: 'Aug',
    month9: 'Sep',
    month10: 'Oct',
    month11: 'Nov',
    month12: 'Dec',
    MONTH: 'MONTH',
    interactionWithSmartyMenu: 'Interactions: with Smarty menu',
    questions: 'Questions',
    numberOfQueries: 'Number of queries',
    menu: 'Menu',
    smartyResponse: 'Smarty\'s response',
    download_xlsx: 'Download excel',
    init_date: "start date",
    end_date: "end date",
    download_xlsx_success: 'Download successfully',
    download_xlsx_fail: 'Download failed',
}