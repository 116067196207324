import * as actionTypes from '../actionTypes';
import {updateState} from '../utilities';

const initialState = {
    workspaceData: null,
    currentWorkspace: null,
};

const updateWorkspaceData = (state, action) => {
    return updateState(
        state,
        {
            workspaceData: action.workspaceData
        }
    )
};

const updateCurrentWorkspace = (state, action) => {
    return updateState(
        state,
        {
            currentWorkspace: action.currentWorkspace
        }
    )
};

const reducer = (state=initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_WORKSPACE_DATA: return updateWorkspaceData(state, action);
        case actionTypes.UPDATE_CURRENT_WORKSPACE: return updateCurrentWorkspace(state, action);
        default: return state;
    }
};

export default reducer;