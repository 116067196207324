import {useEffect, useState} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ThemeProvider} from "styled-components";
import Loadable from "react-loadable";

import {notification} from "antd";
import Loader from "./components/Loader/Loader";
import ThemeLoader from "./components/Loader/ThemeLoader";
import UbotToast from "./components/Wrapper/UbotToast";

import {colorSchema, defaultBaseSchema, defaultColorSchemaIdentifiers, defaultDarkBaseSchema} from "./util/themeUtil";
import {setMessage, setUbotToast} from "./service/redux/action/theme";

import './App.less';

const loading = () => <><Loader/></>;

const WorkspaceLayout = Loadable({
    loader: () => import('./layouts/WorkspaceLayout/WorkspaceLayout'),
    loading,
});

const MainLayout = Loadable({
    loader: () => import('./layouts/MainLayout/MainLayout'),
    loading,
});

function App() {

    const dispatch = useDispatch();

    const theme = useSelector(state => state.theme.theme);
    const institutionData = useSelector(state => state.auth.institutionData);
    const message = useSelector((state) => {
        return {
            type: state.theme.messageType,
            content: state.theme.messageContent,
        };
    });
    const ubotMessage = useSelector(state => {
        return {type: state.theme.ubotToastType, content: state.theme.ubotToastContent};
    });

    const [themeSchema, setThemeSchema] = useState(colorSchema[`default_${theme}`]);

    useEffect(() => {
        if (message.content !== null) {
            notification[message.type]({
                message: message.type.toUpperCase(),
                description: message.content,
                onClose: () => dispatch(setMessage(null, null)),
            });
        }
    }, [message.content]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!!ubotMessage.content) {
            setTimeout(() => dispatch(setUbotToast(null, null)), 5000);
        }
    }, [ubotMessage.content]); // eslint-disable-line react-hooks/exhaustive-deps
	
	useEffect(() => {
		if (institutionData) {
			if (defaultColorSchemaIdentifiers.includes(institutionData.visual_setting?.color)) {
				setThemeSchema(colorSchema[`${institutionData.visual_setting?.color}_${theme}`])
			} else {
				const customSchema = institutionData.color_schemas.find(item => item.identifier === institutionData.visual_setting?.color);
				if (customSchema) {
					setThemeSchema({
						...theme === 'dark' ? defaultDarkBaseSchema : defaultBaseSchema,
						defaultColor: theme === 'dark' ? customSchema.default_color_dark : customSchema.default_color,
						defaultLightColor: theme === 'dark' ? customSchema.default_light_color_dark : customSchema.default_light_color,
						defaultColorHover: theme === 'dark' ? customSchema.default_hover_color_dark : customSchema.default_hover_color,
					})
				}
			}
		}
	}, [institutionData, theme]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`main-layout theme-layout ${theme} color-${!!institutionData && !!institutionData.visual_setting ? institutionData.visual_setting.color : 'default'}`}>
            <UbotToast visible={!!ubotMessage.content} type={ubotMessage.type} content={ubotMessage.content} />
            <ThemeProvider theme={themeSchema}>
                <ThemeLoader/>
                <BrowserRouter>
                    <Switch>
                        <Route path="/workspace" component={WorkspaceLayout}/>
                        <Route path="/" component={MainLayout}/>
                    </Switch>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
